<template>
  <div class="flex h-full w-full relative">
    <Three v-if="isPreviewable" />
    <div
      v-if="isEditable"
      class="z-10 absolute bottom-20 left-[50%] translate-x-[-50%]"
    >
      <El-Button
        class="!border-black bg-transparent text-black px-10"
        @click.prevent="$router.back()"
      >
        返回設定</El-Button
      >
    </div>
  </div>
</template>

<script>
import Three from '@/pages/index/components/three/Index.vue';
import { inject } from 'vue';
import { ElButton, ElMessageBox } from 'element-plus';
import { useRouter } from 'vue-router';

export default {
  components: { Three, ElButton },
  setup() {
    const { isPreviewable, isEditable } = inject('exhibState');
    const router = useRouter();
    if (!isPreviewable) {
      ElMessageBox.alert('展覽上線中，該連結已經失效。', {
        center: true,
        showClose: false,
      }).finally(() => {
        return router.push({ name: 'Home' });
      });
    }
    return { isPreviewable, isEditable };
  },
};
</script>
